import { combine, isEmptyObject, timeStampNow, currentDrift, display, createEventRateLimiter, canUseEventBridge, round, isExperimentalFeatureEnabled, ExperimentalFeature, getConnectivity, addTelemetryDebug } from '@datadog/browser-core';
import { limitModification } from './limitModification';
const VIEW_MODIFIABLE_FIELD_PATHS = {
  'view.name': 'string',
  'view.url': 'string',
  'view.referrer': 'string'
};
const USER_CUSTOMIZABLE_FIELD_PATHS = {
  context: 'object'
};
const ROOT_MODIFIABLE_FIELD_PATHS = {
  service: 'string',
  version: 'string'
};
let modifiableFieldPathsByEvent;
export function startRumAssembly(configuration, lifeCycle, hooks, sessionManager, viewHistory, urlContexts, displayContext, getCommonContext, reportError) {
  modifiableFieldPathsByEvent = {
    ["view" /* RumEventType.VIEW */]: {
      'view.performance.lcp.resource_url': 'string',
      ...USER_CUSTOMIZABLE_FIELD_PATHS,
      ...VIEW_MODIFIABLE_FIELD_PATHS
    },
    ["error" /* RumEventType.ERROR */]: {
      'error.message': 'string',
      'error.stack': 'string',
      'error.resource.url': 'string',
      'error.fingerprint': 'string',
      ...USER_CUSTOMIZABLE_FIELD_PATHS,
      ...VIEW_MODIFIABLE_FIELD_PATHS,
      ...ROOT_MODIFIABLE_FIELD_PATHS
    },
    ["resource" /* RumEventType.RESOURCE */]: {
      'resource.url': 'string',
      ...(isExperimentalFeatureEnabled(ExperimentalFeature.WRITABLE_RESOURCE_GRAPHQL) ? {
        'resource.graphql': 'object'
      } : {}),
      ...USER_CUSTOMIZABLE_FIELD_PATHS,
      ...VIEW_MODIFIABLE_FIELD_PATHS,
      ...ROOT_MODIFIABLE_FIELD_PATHS
    },
    ["action" /* RumEventType.ACTION */]: {
      'action.target.name': 'string',
      ...USER_CUSTOMIZABLE_FIELD_PATHS,
      ...VIEW_MODIFIABLE_FIELD_PATHS,
      ...ROOT_MODIFIABLE_FIELD_PATHS
    },
    ["long_task" /* RumEventType.LONG_TASK */]: {
      'long_task.scripts[].source_url': 'string',
      'long_task.scripts[].invoker': 'string',
      ...USER_CUSTOMIZABLE_FIELD_PATHS,
      ...VIEW_MODIFIABLE_FIELD_PATHS
    },
    ["vital" /* RumEventType.VITAL */]: {
      ...USER_CUSTOMIZABLE_FIELD_PATHS,
      ...VIEW_MODIFIABLE_FIELD_PATHS
    }
  };
  const eventRateLimiters = {
    ["error" /* RumEventType.ERROR */]: createEventRateLimiter("error" /* RumEventType.ERROR */, configuration.eventRateLimiterThreshold, reportError),
    ["action" /* RumEventType.ACTION */]: createEventRateLimiter("action" /* RumEventType.ACTION */, configuration.eventRateLimiterThreshold, reportError),
    ["vital" /* RumEventType.VITAL */]: createEventRateLimiter("vital" /* RumEventType.VITAL */, configuration.eventRateLimiterThreshold, reportError)
  };
  lifeCycle.subscribe(12 /* LifeCycleEventType.RAW_RUM_EVENT_COLLECTED */, ({
    startTime,
    duration,
    rawRumEvent,
    domainContext,
    savedCommonContext,
    customerContext
  }) => {
    const viewHistoryEntry = viewHistory.findView(startTime);
    const urlContext = urlContexts.findUrl(startTime);
    const session = sessionManager.findTrackedSession(startTime);
    if (session && viewHistoryEntry && !urlContext && isExperimentalFeatureEnabled(ExperimentalFeature.MISSING_URL_CONTEXT_TELEMETRY)) {
      addTelemetryDebug('Missing URL entry', {
        debug: {
          eventType: rawRumEvent.type,
          startTime,
          urlEntries: urlContexts.getAllEntries(),
          urlDeletedEntries: urlContexts.getDeletedEntries(),
          viewEntries: viewHistory.getAllEntries(),
          viewDeletedEntries: viewHistory.getDeletedEntries()
        }
      });
    }
    if (session && viewHistoryEntry && urlContext) {
      const commonContext = savedCommonContext || getCommonContext();
      const rumContext = {
        _dd: {
          format_version: 2,
          drift: currentDrift(),
          configuration: {
            session_sample_rate: round(configuration.sessionSampleRate, 3),
            session_replay_sample_rate: round(configuration.sessionReplaySampleRate, 3)
          },
          browser_sdk_version: canUseEventBridge() ? "6.5.0" : undefined
        },
        application: {
          id: configuration.applicationId
        },
        date: timeStampNow(),
        source: 'browser',
        session: {
          id: session.id,
          type: "user" /* SessionType.USER */
        },
        display: displayContext.get(),
        connectivity: getConnectivity(),
        context: commonContext.context
      };
      const serverRumEvent = combine(rumContext, hooks.triggerHook(0 /* HookNames.Assemble */, {
        eventType: rawRumEvent.type,
        startTime,
        duration
      }), {
        context: customerContext
      }, rawRumEvent);
      if (!('has_replay' in serverRumEvent.session)) {
        ;
        serverRumEvent.session.has_replay = commonContext.hasReplay;
      }
      if (serverRumEvent.type === 'view') {
        ;
        serverRumEvent.session.sampled_for_replay = session.sessionReplay === 1 /* SessionReplayState.SAMPLED */;
      }
      if (session.anonymousId && !commonContext.user.anonymous_id && !!configuration.trackAnonymousUser) {
        commonContext.user.anonymous_id = session.anonymousId;
      }
      if (!isEmptyObject(commonContext.user)) {
        ;
        serverRumEvent.usr = commonContext.user;
      }
      if (!isEmptyObject(commonContext.account) && commonContext.account.id) {
        ;
        serverRumEvent.account = commonContext.account;
      }
      if (shouldSend(serverRumEvent, configuration.beforeSend, domainContext, eventRateLimiters)) {
        if (isEmptyObject(serverRumEvent.context)) {
          delete serverRumEvent.context;
        }
        lifeCycle.notify(13 /* LifeCycleEventType.RUM_EVENT_COLLECTED */, serverRumEvent);
      }
    }
  });
}
function shouldSend(event, beforeSend, domainContext, eventRateLimiters) {
  var _a;
  if (beforeSend) {
    const result = limitModification(event, modifiableFieldPathsByEvent[event.type], event => beforeSend(event, domainContext));
    if (result === false && event.type !== "view" /* RumEventType.VIEW */) {
      return false;
    }
    if (result === false) {
      display.warn("Can't dismiss view events using beforeSend!");
    }
  }
  const rateLimitReached = (_a = eventRateLimiters[event.type]) === null || _a === void 0 ? void 0 : _a.isLimitReached();
  return !rateLimitReached;
}
