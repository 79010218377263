import { SESSION_TIME_OUT_DELAY, createValueHistory, isEmptyObject } from '@datadog/browser-core';
export const FEATURE_FLAG_CONTEXT_TIME_OUT_DELAY = SESSION_TIME_OUT_DELAY;
export const BYTES_COMPUTATION_THROTTLING_DELAY = 200;
/**
 * Start feature flag contexts
 *
 * Feature flag contexts follow the life of views.
 * A new context is added when a view is created and ended when the view is ended
 *
 * Note: we choose not to add a new context at each evaluation to save memory
 */
export function startFeatureFlagContexts(lifeCycle, hooks, configuration, customerDataTracker) {
  const featureFlagContexts = createValueHistory({
    expireDelay: FEATURE_FLAG_CONTEXT_TIME_OUT_DELAY
  });
  lifeCycle.subscribe(1 /* LifeCycleEventType.BEFORE_VIEW_CREATED */, ({
    startClocks
  }) => {
    featureFlagContexts.add({}, startClocks.relative);
    customerDataTracker.resetCustomerData();
  });
  lifeCycle.subscribe(6 /* LifeCycleEventType.AFTER_VIEW_ENDED */, ({
    endClocks
  }) => {
    featureFlagContexts.closeActive(endClocks.relative);
  });
  hooks.register(0 /* HookNames.Assemble */, ({
    startTime,
    eventType
  }) => {
    const trackFeatureFlagsForEvents = configuration.trackFeatureFlagsForEvents.concat(["view" /* RumEventType.VIEW */, "error" /* RumEventType.ERROR */]);
    if (!trackFeatureFlagsForEvents.includes(eventType)) {
      return;
    }
    const featureFlagContext = featureFlagContexts.find(startTime);
    if (!featureFlagContext || isEmptyObject(featureFlagContext)) {
      return;
    }
    return {
      type: eventType,
      feature_flags: featureFlagContext
    };
  });
  return {
    addFeatureFlagEvaluation: (key, value) => {
      const currentContext = featureFlagContexts.find();
      if (currentContext) {
        currentContext[key] = value;
        customerDataTracker.updateCustomerData(currentContext);
      }
    },
    stop: () => customerDataTracker.stop()
  };
}
