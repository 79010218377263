import { createBoundedBuffer, display, canUseEventBridge, displayAlreadyInitializedError, willSyntheticsInjectRum, noop, timeStampNow, clocksNow, getEventBridge, initFeatureFlags, addTelemetryConfiguration, initFetchObservable } from '@datadog/browser-core';
import { validateAndBuildRumConfiguration } from '../domain/configuration';
import { startDurationVital, stopDurationVital } from '../domain/vital/vitalCollection';
import { fetchAndApplyRemoteConfiguration, serializeRumConfiguration } from '../domain/configuration';
import { callPluginsMethod } from '../domain/plugins';
export function createPreStartStrategy({
  ignoreInitIfSyntheticsWillInjectRum,
  startDeflateWorker
}, getCommonContext, trackingConsentState, customVitalsState, doStartRum) {
  const bufferApiCalls = createBoundedBuffer();
  let firstStartViewCall;
  let deflateWorker;
  let cachedInitConfiguration;
  let cachedConfiguration;
  const trackingConsentStateSubscription = trackingConsentState.observable.subscribe(tryStartRum);
  const emptyContext = {};
  function tryStartRum() {
    if (!cachedInitConfiguration || !cachedConfiguration || !trackingConsentState.isGranted()) {
      return;
    }
    trackingConsentStateSubscription.unsubscribe();
    let initialViewOptions;
    if (cachedConfiguration.trackViewsManually) {
      if (!firstStartViewCall) {
        return;
      }
      // An initial view is always created when starting RUM.
      // When tracking views automatically, any startView call before RUM start creates an extra
      // view.
      // When tracking views manually, we use the ViewOptions from the first startView call as the
      // initial view options, and we remove the actual startView call so we don't create an extra
      // view.
      bufferApiCalls.remove(firstStartViewCall.callback);
      initialViewOptions = firstStartViewCall.options;
    }
    const startRumResult = doStartRum(cachedConfiguration, deflateWorker, initialViewOptions);
    bufferApiCalls.drain(startRumResult);
  }
  function doInit(initConfiguration) {
    const eventBridgeAvailable = canUseEventBridge();
    if (eventBridgeAvailable) {
      initConfiguration = overrideInitConfigurationForBridge(initConfiguration);
    }
    // Update the exposed initConfiguration to reflect the bridge and remote configuration overrides
    cachedInitConfiguration = initConfiguration;
    addTelemetryConfiguration(serializeRumConfiguration(initConfiguration));
    if (cachedConfiguration) {
      displayAlreadyInitializedError('DD_RUM', initConfiguration);
      return;
    }
    const configuration = validateAndBuildRumConfiguration(initConfiguration);
    if (!configuration) {
      return;
    }
    if (!eventBridgeAvailable && !configuration.sessionStoreStrategyType) {
      display.warn('No storage available for session. We will not send any data.');
      return;
    }
    if (configuration.compressIntakeRequests && !eventBridgeAvailable && startDeflateWorker) {
      deflateWorker = startDeflateWorker(configuration, 'Datadog RUM',
      // Worker initialization can fail asynchronously, especially in Firefox where even CSP
      // issues are reported asynchronously. For now, the SDK will continue its execution even if
      // data won't be sent to Datadog. We could improve this behavior in the future.
      noop);
      if (!deflateWorker) {
        // `startDeflateWorker` should have logged an error message explaining the issue
        return;
      }
    }
    cachedConfiguration = configuration;
    // Instrumuent fetch to track network requests
    // This is needed in case the consent is not granted and some cutsomer
    // library (Apollo Client) is storing uninstrumented fetch to be used later
    // The subscrption is needed so that the instrumentation process is completed
    initFetchObservable().subscribe(noop);
    trackingConsentState.tryToInit(configuration.trackingConsent);
    tryStartRum();
  }
  const addDurationVital = vital => {
    bufferApiCalls.add(startRumResult => startRumResult.addDurationVital(vital));
  };
  const strategy = {
    init(initConfiguration, publicApi) {
      if (!initConfiguration) {
        display.error('Missing configuration');
        return;
      }
      // Set the experimental feature flags as early as possible, so we can use them in most places
      initFeatureFlags(initConfiguration.enableExperimentalFeatures);
      // Expose the initial configuration regardless of initialization success.
      cachedInitConfiguration = initConfiguration;
      // If we are in a Synthetics test configured to automatically inject a RUM instance, we want
      // to completely discard the customer application RUM instance by ignoring their init() call.
      // But, we should not ignore the init() call from the Synthetics-injected RUM instance, so the
      // internal `ignoreInitIfSyntheticsWillInjectRum` option is here to bypass this condition.
      if (ignoreInitIfSyntheticsWillInjectRum && willSyntheticsInjectRum()) {
        return;
      }
      callPluginsMethod(initConfiguration.plugins, 'onInit', {
        initConfiguration,
        publicApi
      });
      if (initConfiguration.remoteConfigurationId) {
        fetchAndApplyRemoteConfiguration(initConfiguration, doInit);
      } else {
        doInit(initConfiguration);
      }
    },
    get initConfiguration() {
      return cachedInitConfiguration;
    },
    getInternalContext: noop,
    stopSession: noop,
    addTiming(name, time = timeStampNow()) {
      bufferApiCalls.add(startRumResult => startRumResult.addTiming(name, time));
    },
    startView(options, startClocks = clocksNow()) {
      const callback = startRumResult => {
        startRumResult.startView(options, startClocks);
      };
      bufferApiCalls.add(callback);
      if (!firstStartViewCall) {
        firstStartViewCall = {
          options,
          callback
        };
        tryStartRum();
      }
    },
    setViewName(name) {
      bufferApiCalls.add(startRumResult => startRumResult.setViewName(name));
    },
    setViewContext(context) {
      bufferApiCalls.add(startRumResult => startRumResult.setViewContext(context));
    },
    setViewContextProperty(key, value) {
      bufferApiCalls.add(startRumResult => startRumResult.setViewContextProperty(key, value));
    },
    getViewContext: () => emptyContext,
    addAction(action, commonContext = getCommonContext()) {
      bufferApiCalls.add(startRumResult => startRumResult.addAction(action, commonContext));
    },
    addError(providedError, commonContext = getCommonContext()) {
      bufferApiCalls.add(startRumResult => startRumResult.addError(providedError, commonContext));
    },
    addFeatureFlagEvaluation(key, value) {
      bufferApiCalls.add(startRumResult => startRumResult.addFeatureFlagEvaluation(key, value));
    },
    startDurationVital(name, options) {
      return startDurationVital(customVitalsState, name, options);
    },
    stopDurationVital(name, options) {
      stopDurationVital(addDurationVital, customVitalsState, name, options);
    },
    addDurationVital
  };
  return strategy;
}
function overrideInitConfigurationForBridge(initConfiguration) {
  var _a, _b;
  return {
    ...initConfiguration,
    applicationId: '00000000-aaaa-0000-aaaa-000000000000',
    clientToken: 'empty',
    sessionSampleRate: 100,
    defaultPrivacyLevel: (_a = initConfiguration.defaultPrivacyLevel) !== null && _a !== void 0 ? _a : (_b = getEventBridge()) === null || _b === void 0 ? void 0 : _b.getPrivacyLevel()
  };
}
