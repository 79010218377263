import { combine } from '@datadog/browser-core';
export function createHooks() {
  const callbacks = {};
  return {
    register(hookName, callback) {
      if (!callbacks[hookName]) {
        callbacks[hookName] = [];
      }
      callbacks[hookName].push(callback);
      return {
        unregister: () => {
          callbacks[hookName] = callbacks[hookName].filter(cb => cb !== callback);
        }
      };
    },
    triggerHook(hookName, param) {
      const hookCallbacks = callbacks[hookName] || [];
      const results = hookCallbacks.map(callback => callback(param));
      return combine(...results);
    }
  };
}
