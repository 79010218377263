import { getInitCookie } from '@datadog/browser-core';
import { createCookieObservable } from '../../browser/cookieObservable';
export const CI_VISIBILITY_TEST_ID_COOKIE_NAME = 'datadog-ci-visibility-test-execution-id';
export function startCiVisibilityContext(configuration, hooks, cookieObservable = createCookieObservable(configuration, CI_VISIBILITY_TEST_ID_COOKIE_NAME)) {
  var _a;
  let testExecutionId = getInitCookie(CI_VISIBILITY_TEST_ID_COOKIE_NAME) || ((_a = window.Cypress) === null || _a === void 0 ? void 0 : _a.env('traceId'));
  const cookieObservableSubscription = cookieObservable.subscribe(value => {
    testExecutionId = value;
  });
  hooks.register(0 /* HookNames.Assemble */, ({
    eventType
  }) => {
    if (typeof testExecutionId !== 'string') {
      return;
    }
    return {
      type: eventType,
      session: {
        type: "ci_test" /* SessionType.CI_TEST */
      },
      ci_test: {
        test_execution_id: testExecutionId
      }
    };
  });
  return {
    stop: () => {
      cookieObservableSubscription.unsubscribe();
    }
  };
}
