import { noop, combine, toServerDuration, generateUUID } from '@datadog/browser-core';
import { discardNegativeDuration } from '../discardNegativeDuration';
import { trackClickActions } from './trackClickActions';
export function startActionCollection(lifeCycle, hooks, domMutationObservable, windowOpenObservable, configuration) {
  lifeCycle.subscribe(0 /* LifeCycleEventType.AUTO_ACTION_COMPLETED */, action => lifeCycle.notify(12 /* LifeCycleEventType.RAW_RUM_EVENT_COLLECTED */, processAction(action)));
  hooks.register(0 /* HookNames.Assemble */, ({
    startTime,
    eventType
  }) => {
    if (eventType !== "error" /* RumEventType.ERROR */ && eventType !== "resource" /* RumEventType.RESOURCE */ && eventType !== "long_task" /* RumEventType.LONG_TASK */) {
      return;
    }
    const actionId = actionContexts.findActionId(startTime);
    if (!actionId) {
      return;
    }
    return {
      type: eventType,
      action: {
        id: actionId
      }
    };
  });
  let actionContexts = {
    findActionId: noop
  };
  let stop = noop;
  if (configuration.trackUserInteractions) {
    ;
    ({
      actionContexts,
      stop
    } = trackClickActions(lifeCycle, domMutationObservable, windowOpenObservable, configuration));
  }
  return {
    addAction: (action, savedCommonContext) => {
      lifeCycle.notify(12 /* LifeCycleEventType.RAW_RUM_EVENT_COLLECTED */, {
        savedCommonContext,
        ...processAction(action)
      });
    },
    actionContexts,
    stop
  };
}
function processAction(action) {
  const autoActionProperties = isAutoAction(action) ? {
    action: {
      id: action.id,
      loading_time: discardNegativeDuration(toServerDuration(action.duration)),
      frustration: {
        type: action.frustrationTypes
      },
      error: {
        count: action.counts.errorCount
      },
      long_task: {
        count: action.counts.longTaskCount
      },
      resource: {
        count: action.counts.resourceCount
      }
    },
    _dd: {
      action: {
        target: action.target,
        position: action.position,
        name_source: action.nameSource
      }
    }
  } : undefined;
  const actionEvent = combine({
    action: {
      id: generateUUID(),
      target: {
        name: action.name
      },
      type: action.type
    },
    date: action.startClocks.timeStamp,
    type: "action" /* RumEventType.ACTION */
  }, autoActionProperties);
  const duration = isAutoAction(action) ? action.duration : undefined;
  const customerContext = !isAutoAction(action) ? action.context : undefined;
  const domainContext = isAutoAction(action) ? {
    events: action.events
  } : {
    handlingStack: action.handlingStack
  };
  return {
    customerContext,
    rawRumEvent: actionEvent,
    duration,
    startTime: action.startClocks.relative,
    domainContext
  };
}
function isAutoAction(action) {
  return action.type !== "custom" /* ActionType.CUSTOM */;
}
