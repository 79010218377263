import { round, ONE_SECOND, noop, elapsed } from '@datadog/browser-core';
import { isElementNode } from '../../../browser/htmlDomUtils';
import { supportPerformanceTimingEvent, RumPerformanceEntryType, createPerformanceObservable } from '../../../browser/performanceObservable';
import { getSelectorFromElement } from '../../getSelectorFromElement';
/**
 * Track the cumulative layout shifts (CLS).
 * Layout shifts are grouped into session windows.
 * The minimum gap between session windows is 1 second.
 * The maximum duration of a session window is 5 second.
 * The session window layout shift value is the sum of layout shifts inside it.
 * The CLS value is the max of session windows values.
 *
 * This yields a new value whenever the CLS value is updated (a higher session window value is computed).
 *
 * See isLayoutShiftSupported to check for browser support.
 *
 * Documentation:
 * https://web.dev/cls/
 * https://web.dev/evolving-cls/
 * Reference implementation: https://github.com/GoogleChrome/web-vitals/blob/master/src/getCLS.ts
 */
export function trackCumulativeLayoutShift(configuration, viewStart, callback) {
  if (!isLayoutShiftSupported()) {
    return {
      stop: noop
    };
  }
  let maxClsValue = 0;
  let biggestShift;
  // if no layout shift happen the value should be reported as 0
  callback({
    value: 0
  });
  const slidingWindow = slidingSessionWindow();
  const performanceSubscription = createPerformanceObservable(configuration, {
    type: RumPerformanceEntryType.LAYOUT_SHIFT,
    buffered: true
  }).subscribe(entries => {
    var _a;
    for (const entry of entries) {
      if (entry.hadRecentInput || entry.startTime < viewStart) {
        continue;
      }
      const {
        cumulatedValue,
        isMaxValue
      } = slidingWindow.update(entry);
      if (isMaxValue) {
        const attribution = getFirstElementAttribution(entry.sources);
        biggestShift = {
          target: (attribution === null || attribution === void 0 ? void 0 : attribution.node) ? new WeakRef(attribution.node) : undefined,
          time: elapsed(viewStart, entry.startTime),
          previousRect: attribution === null || attribution === void 0 ? void 0 : attribution.previousRect,
          currentRect: attribution === null || attribution === void 0 ? void 0 : attribution.currentRect,
          devicePixelRatio: window.devicePixelRatio
        };
      }
      if (cumulatedValue > maxClsValue) {
        maxClsValue = cumulatedValue;
        const target = (_a = biggestShift === null || biggestShift === void 0 ? void 0 : biggestShift.target) === null || _a === void 0 ? void 0 : _a.deref();
        callback({
          value: round(maxClsValue, 4),
          targetSelector: target && getSelectorFromElement(target, configuration.actionNameAttribute),
          time: biggestShift === null || biggestShift === void 0 ? void 0 : biggestShift.time,
          previousRect: (biggestShift === null || biggestShift === void 0 ? void 0 : biggestShift.previousRect) ? asRumRect(biggestShift.previousRect) : undefined,
          currentRect: (biggestShift === null || biggestShift === void 0 ? void 0 : biggestShift.currentRect) ? asRumRect(biggestShift.currentRect) : undefined,
          devicePixelRatio: biggestShift === null || biggestShift === void 0 ? void 0 : biggestShift.devicePixelRatio
        });
      }
    }
  });
  return {
    stop: () => {
      performanceSubscription.unsubscribe();
    }
  };
}
function getFirstElementAttribution(sources) {
  return sources.find(source => !!source.node && isElementNode(source.node));
}
function asRumRect({
  x,
  y,
  width,
  height
}) {
  return {
    x,
    y,
    width,
    height
  };
}
export const MAX_WINDOW_DURATION = 5 * ONE_SECOND;
const MAX_UPDATE_GAP = ONE_SECOND;
function slidingSessionWindow() {
  let cumulatedValue = 0;
  let startTime;
  let endTime;
  let maxValue = 0;
  return {
    update: entry => {
      const shouldCreateNewWindow = startTime === undefined || entry.startTime - endTime >= MAX_UPDATE_GAP || entry.startTime - startTime >= MAX_WINDOW_DURATION;
      let isMaxValue;
      if (shouldCreateNewWindow) {
        startTime = endTime = entry.startTime;
        maxValue = cumulatedValue = entry.value;
        isMaxValue = true;
      } else {
        cumulatedValue += entry.value;
        endTime = entry.startTime;
        isMaxValue = entry.value > maxValue;
        if (isMaxValue) {
          maxValue = entry.value;
        }
      }
      return {
        cumulatedValue,
        isMaxValue
      };
    }
  };
}
/**
 * Check whether `layout-shift` is supported by the browser.
 */
export function isLayoutShiftSupported() {
  return supportPerformanceTimingEvent(RumPerformanceEntryType.LAYOUT_SHIFT) && 'WeakRef' in window;
}
