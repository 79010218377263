import { elapsed, createValueHistory, SESSION_TIME_OUT_DELAY, toServerDuration, addEventListeners, relativeNow } from '@datadog/browser-core';
// Arbitrary value to cap number of element for memory consumption in the browser
export const MAX_PAGE_STATE_ENTRIES = 4000;
// Arbitrary value to cap number of element for backend & to save bandwidth
export const MAX_PAGE_STATE_ENTRIES_SELECTABLE = 500;
export const PAGE_STATE_CONTEXT_TIME_OUT_DELAY = SESSION_TIME_OUT_DELAY;
export function startPageStateHistory(hooks, configuration, maxPageStateEntriesSelectable = MAX_PAGE_STATE_ENTRIES_SELECTABLE) {
  const pageStateEntryHistory = createValueHistory({
    expireDelay: PAGE_STATE_CONTEXT_TIME_OUT_DELAY,
    maxEntries: MAX_PAGE_STATE_ENTRIES
  });
  let currentPageState;
  addPageState(getPageState(), relativeNow());
  const {
    stop: stopEventListeners
  } = addEventListeners(configuration, window, ["pageshow" /* DOM_EVENT.PAGE_SHOW */, "focus" /* DOM_EVENT.FOCUS */, "blur" /* DOM_EVENT.BLUR */, "visibilitychange" /* DOM_EVENT.VISIBILITY_CHANGE */, "resume" /* DOM_EVENT.RESUME */, "freeze" /* DOM_EVENT.FREEZE */, "pagehide" /* DOM_EVENT.PAGE_HIDE */], event => {
    addPageState(computePageState(event), event.timeStamp);
  }, {
    capture: true
  });
  function addPageState(nextPageState, startTime = relativeNow()) {
    if (nextPageState === currentPageState) {
      return;
    }
    currentPageState = nextPageState;
    pageStateEntryHistory.closeActive(startTime);
    pageStateEntryHistory.add({
      state: currentPageState,
      startTime
    }, startTime);
  }
  function wasInPageStateDuringPeriod(state, startTime, duration) {
    return pageStateEntryHistory.findAll(startTime, duration).some(pageState => pageState.state === state);
  }
  hooks.register(0 /* HookNames.Assemble */, ({
    startTime,
    duration = 0,
    eventType
  }) => {
    if (eventType === "view" /* RumEventType.VIEW */) {
      const pageStates = pageStateEntryHistory.findAll(startTime, duration);
      return {
        type: eventType,
        _dd: {
          page_states: processPageStates(pageStates, startTime, maxPageStateEntriesSelectable)
        }
      };
    }
    if (eventType === "action" /* RumEventType.ACTION */ || eventType === "error" /* RumEventType.ERROR */) {
      return {
        type: eventType,
        view: {
          in_foreground: wasInPageStateDuringPeriod("active" /* PageState.ACTIVE */, startTime, 0)
        }
      };
    }
  });
  return {
    wasInPageStateDuringPeriod,
    addPageState,
    stop: () => {
      stopEventListeners();
      pageStateEntryHistory.stop();
    }
  };
}
function processPageStates(pageStateEntries, eventStartTime, maxPageStateEntriesSelectable) {
  if (pageStateEntries.length === 0) {
    return;
  }
  return pageStateEntries.slice(-maxPageStateEntriesSelectable).reverse().map(({
    state,
    startTime
  }) => ({
    state,
    start: toServerDuration(elapsed(eventStartTime, startTime))
  }));
}
function computePageState(event) {
  if (event.type === "freeze" /* DOM_EVENT.FREEZE */) {
    return "frozen" /* PageState.FROZEN */;
  } else if (event.type === "pagehide" /* DOM_EVENT.PAGE_HIDE */) {
    return event.persisted ? "frozen" /* PageState.FROZEN */ : "terminated" /* PageState.TERMINATED */;
  }
  return getPageState();
}
function getPageState() {
  if (document.visibilityState === 'hidden') {
    return "hidden" /* PageState.HIDDEN */;
  }
  if (document.hasFocus()) {
    return "active" /* PageState.ACTIVE */;
  }
  return "passive" /* PageState.PASSIVE */;
}
