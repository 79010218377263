import { getSyntheticsResultId, getSyntheticsTestId, willSyntheticsInjectRum } from '@datadog/browser-core';
export function startSyntheticsContext(hooks) {
  hooks.register(0 /* HookNames.Assemble */, ({
    eventType
  }) => {
    const testId = getSyntheticsTestId();
    const resultId = getSyntheticsResultId();
    if (!testId || !resultId) {
      return;
    }
    return {
      type: eventType,
      session: {
        type: "synthetics" /* SessionType.SYNTHETICS */
      },
      synthetics: {
        test_id: testId,
        result_id: resultId,
        injected: willSyntheticsInjectRum()
      }
    };
  });
}
