import { combine, generateUUID, toServerDuration, relativeToClocks, createTaskQueue } from '@datadog/browser-core';
import { RumPerformanceEntryType, createPerformanceObservable } from '../../browser/performanceObservable';
import { createSpanIdentifier } from '../tracing/identifier';
import { matchRequestResourceEntry } from './matchRequestResourceEntry';
import { computeResourceEntryDetails, computeResourceEntryDuration, computeResourceEntryType, computeResourceEntrySize, computeResourceEntryProtocol, computeResourceEntryDeliveryType, isResourceEntryRequestType, isLongDataUrl, sanitizeDataUrl } from './resourceUtils';
import { retrieveInitialDocumentResourceTiming } from './retrieveInitialDocumentResourceTiming';
export function startResourceCollection(lifeCycle, configuration, pageStateHistory, taskQueue = createTaskQueue(), retrieveInitialDocumentResourceTimingImpl = retrieveInitialDocumentResourceTiming) {
  lifeCycle.subscribe(8 /* LifeCycleEventType.REQUEST_COMPLETED */, request => {
    handleResource(() => processRequest(request, configuration, pageStateHistory));
  });
  const performanceResourceSubscription = createPerformanceObservable(configuration, {
    type: RumPerformanceEntryType.RESOURCE,
    buffered: true
  }).subscribe(entries => {
    for (const entry of entries) {
      if (!isResourceEntryRequestType(entry)) {
        handleResource(() => processResourceEntry(entry, configuration));
      }
    }
  });
  retrieveInitialDocumentResourceTimingImpl(configuration, timing => {
    handleResource(() => processResourceEntry(timing, configuration));
  });
  function handleResource(computeRawEvent) {
    taskQueue.push(() => {
      const rawEvent = computeRawEvent();
      if (rawEvent) {
        lifeCycle.notify(12 /* LifeCycleEventType.RAW_RUM_EVENT_COLLECTED */, rawEvent);
      }
    });
  }
  return {
    stop: () => {
      performanceResourceSubscription.unsubscribe();
    }
  };
}
function processRequest(request, configuration, pageStateHistory) {
  const matchingTiming = matchRequestResourceEntry(request);
  const startClocks = matchingTiming ? relativeToClocks(matchingTiming.startTime) : request.startClocks;
  const tracingInfo = computeRequestTracingInfo(request, configuration);
  if (!configuration.trackResources && !tracingInfo) {
    return;
  }
  const type = request.type === "xhr" /* RequestType.XHR */ ? "xhr" /* ResourceType.XHR */ : "fetch" /* ResourceType.FETCH */;
  const correspondingTimingOverrides = matchingTiming ? computeResourceEntryMetrics(matchingTiming) : undefined;
  const duration = matchingTiming ? computeResourceEntryDuration(matchingTiming) : computeRequestDuration(pageStateHistory, startClocks, request.duration);
  const resourceEvent = combine({
    date: startClocks.timeStamp,
    resource: {
      id: generateUUID(),
      type,
      duration: toServerDuration(duration),
      method: request.method,
      status_code: request.status,
      protocol: matchingTiming && computeResourceEntryProtocol(matchingTiming),
      url: isLongDataUrl(request.url) ? sanitizeDataUrl(request.url) : request.url,
      delivery_type: matchingTiming && computeResourceEntryDeliveryType(matchingTiming)
    },
    type: "resource" /* RumEventType.RESOURCE */,
    _dd: {
      discarded: !configuration.trackResources
    }
  }, tracingInfo, correspondingTimingOverrides);
  return {
    startTime: startClocks.relative,
    duration,
    rawRumEvent: resourceEvent,
    domainContext: {
      performanceEntry: matchingTiming,
      xhr: request.xhr,
      response: request.response,
      requestInput: request.input,
      requestInit: request.init,
      error: request.error,
      isAborted: request.isAborted,
      handlingStack: request.handlingStack
    }
  };
}
function processResourceEntry(entry, configuration) {
  const startClocks = relativeToClocks(entry.startTime);
  const tracingInfo = computeResourceEntryTracingInfo(entry, configuration);
  if (!configuration.trackResources && !tracingInfo) {
    return;
  }
  const type = computeResourceEntryType(entry);
  const entryMetrics = computeResourceEntryMetrics(entry);
  const duration = computeResourceEntryDuration(entry);
  const resourceEvent = combine({
    date: startClocks.timeStamp,
    resource: {
      id: generateUUID(),
      type,
      duration: toServerDuration(duration),
      url: entry.name,
      status_code: discardZeroStatus(entry.responseStatus),
      protocol: computeResourceEntryProtocol(entry),
      delivery_type: computeResourceEntryDeliveryType(entry)
    },
    type: "resource" /* RumEventType.RESOURCE */,
    _dd: {
      discarded: !configuration.trackResources
    }
  }, tracingInfo, entryMetrics);
  return {
    startTime: startClocks.relative,
    duration,
    rawRumEvent: resourceEvent,
    domainContext: {
      performanceEntry: entry
    }
  };
}
function computeResourceEntryMetrics(entry) {
  const {
    renderBlockingStatus
  } = entry;
  return {
    resource: {
      render_blocking_status: renderBlockingStatus,
      ...computeResourceEntrySize(entry),
      ...computeResourceEntryDetails(entry)
    }
  };
}
function computeRequestTracingInfo(request, configuration) {
  const hasBeenTraced = request.traceSampled && request.traceId && request.spanId;
  if (!hasBeenTraced) {
    return undefined;
  }
  return {
    _dd: {
      span_id: request.spanId.toString(),
      trace_id: request.traceId.toString(),
      rule_psr: configuration.rulePsr
    }
  };
}
function computeResourceEntryTracingInfo(entry, configuration) {
  const hasBeenTraced = entry.traceId;
  if (!hasBeenTraced) {
    return undefined;
  }
  return {
    _dd: {
      trace_id: entry.traceId,
      span_id: createSpanIdentifier().toString(),
      rule_psr: configuration.rulePsr
    }
  };
}
function computeRequestDuration(pageStateHistory, startClocks, duration) {
  return !pageStateHistory.wasInPageStateDuringPeriod("frozen" /* PageState.FROZEN */, startClocks.relative, duration) ? duration : undefined;
}
/**
 * The status is 0 for cross-origin resources without CORS headers, so the status is meaningless, and we shouldn't report it
 * https://developer.mozilla.org/en-US/docs/Web/API/PerformanceResourceTiming/responseStatus#cross-origin_response_status_codes
 */
function discardZeroStatus(statusCode) {
  return statusCode === 0 ? undefined : statusCode;
}
